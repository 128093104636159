function App() {
  return (
    <div className="flex flex-col  justify-center sm:mx-[10px] md:mx-[50px] lg:mx-[200px] sm:mt-[10px] md:mt-[50px] mb-[20px] text_body">
      <p className="h1">Privacy Policy</p>
      <p>
        This privacy policy (“Policy”) describes how Zpoken OU (“Company”, “we”,
        “our”, or “us”) collects, uses, shares, and stores personal information
        of users of its websites, https://penumbra.zpoken.io  (the “Sites”).
        This Policy applies to the Sites, applications, products and services
        (collectively, “Services”) on or in which it is posted, linked, or
        referenced.
      </p>
      <p>
        By using the Services, you accept the terms of this Policy and our Terms
        of Use, and consent to our collection, use, disclosure, and retention of
        your information as described in this Policy.  If you have not done so
        already, please also review our terms of use. The terms of use contain
        provisions that limit our liability to you and require you to resolve
        any dispute with us on an individual basis and not as part of any class
        or representative action. IF YOU DO NOT AGREE WITH ANY PART OF THIS
        PRIVACY POLICY OR OUR TERMS OF USE, THEN PLEASE DO NOT USE ANY OF THE
        SERVICES.
      </p>
      <p>
        Please note that this Policy does not apply to information collected
        through third-party websites or services that you may access through the
        Services or that you submit to us through email, text message or other
        electronic message or offline. If you are visiting the Sites from the
        European Union (EU), see our Notice to EU Data Subjects below for our
        legal bases for processing and transfer of your data.
      </p>
      <p className="h2">WHAT WE COLLECT</p>
      <p>
        We get information about you in a range of ways. **Information You Give
        Us.** Information we collect from you may include:
      </p>
      <ul>
        <li>
          Identity information, such as your first name, last name, username or
          similar identifier, title, date of birth and gender;
        </li>
        <li>
          Contact information, such as your postal address, email address and
          telephone number;
        </li>
        <li>
          Profile information, such as your username and password, interests,
          preferences, feedback and survey responses;
        </li>
        <li>
          Feedback and correspondence, such as information you provide in your
          responses to surveys, when you participate in market research
          activities, report a problem with Service, receive customer support or
          otherwise correspond with us;
        </li>
        <li>
          Financial information, such as your credit card or other payment card
          details;
        </li>
        <li>
          Transaction information, such details about purchases you make through
          the Service and billing details;
        </li>
        <li>
          Usage information, such as information about how you use the Service
          and interact with us;
        </li>
        <li>
          Marketing information, such as your preferences for receiving
          marketing communications and details about how you engage with them;
        </li>
        <li>
          Financial information, such as bank account number and bank routing
          number; financial assets holdings; and
        </li>
        <li>
          Technical information, such as your application programming interface
          (API)-key and network information regarding transactions. Please note
          that your Penumbra wallet address is not collected by
          https://penumbra.zpoken.io/ or associated plugins and mobile
          applications (“Penumbra Wallet”).
        </li>
        <li>
          The use of information received from Google APIs will adhere to the
          Chrome Web Store User Data Policy, including the Limited Use
          requirements
        </li>
      </ul>
      <p>
        **Information Automatically Collected.** We may automatically record
        certain information about how you use our Sites (we refer to this
        information as “Log Data“). Log Data may include information such as a
        user’s Internet Protocol (IP) address, device and browser type,
        operating system, the pages or features of our Sites to which a user
        browsed and the time spent on those pages or features, the frequency
        with which the Sites are used by a user, search terms, the links on our
        Sites that a user clicked on or used, and other statistics. We use this
        information to administer the Service and we analyze (and may engage
        third parties to analyze) this information to improve and enhance the
        Service by expanding its features and functionality and tailoring it to
        our users’ needs and preferences. We may use cookies, local storage or
        similar technologies to analyze trends, administer the Sites, track
        users’ movements around the Sites, and to gather demographic information
        about our user base as a whole. Users can control the use of cookies and
        local storage at the individual browser level.
      </p>
      <p>
        Information we will never collect. We will never ask you to share your
        private keys or wallet seed. Never trust anyone or any site that asks
        you to enter your private keys or wallet seed.
      </p>
      <p className="h2">USE OF PERSONAL INFORMATION</p>
      <p className="h3">To provide our service</p>
      <p>We will use your personal information in the following ways:</p>
      <ul>
        <li>To enable you to access and use the Services</li>
        <li>
          To provide and deliver products and services that you may request.
        </li>
        <li>
          To process and complete transactions, and send you related
          information, including purchase confirmations and invoices
        </li>
        <li>
          To send information, including confirmations, technical notices,
          updates, security alerts, and support and administrative messages.
        </li>
      </ul>
      <p className="h3">To comply with law</p>
      <p>
        We use your personal information as we believe necessary or appropriate
        to comply with applicable laws (including anti-money laundering (AML)
        laws and know-your-customer (KYC) requirements), lawful requests and
        legal process, such as to respond to subpoenas or requests from
        government authorities.
      </p>
      <p className="h3">To communicate with you</p>
      <p>
        We use your personal information to communicate about promotions,
        upcoming events, and other news about products and services offered by
        us and our selected partners.
      </p>
      <p className="h3">To optimize our platform</p>
      <p>
        In order to optimize your user experience, we may use your personal
        information to operate, maintain, and improve our Services. We may also
        use your information to respond to your comments and questions regarding
        the Services, and to provide you and other users with general customer
        service.
      </p>
      <p className="h3">With your consent</p>
      <p>
        We may use or share your personal information with your consent, such as
        when you consent to let us post your testimonials or endorsements on our
        Sites, you instruct us to take a specific action with respect to your
        personal information, or you opt into third party marketing
        communications.
      </p>
      <p className="h3">For compliance, fraud prevention, and safety</p>
      <p>
        We may use your personal information to protect, investigate, and deter
        against fraudulent, unauthorized, or illegal activity
      </p>
      <p className="h2">
        We may use your personal information to protect, investigate, and deter
        against fraudulent, unauthorized, or illegal activity
      </p>
      <p>
        We do not share the personal information that you provide us with other
        organizations without your express consent, except as described in this
        Privacy Policy. We disclose personal information to third parties under
        the following circumstances:
      </p>
      <ul>
        <li>
          Affiliates. We may disclose your personal information to our
          subsidiaries and corporate affiliates (i.e. our family of companies
          that are related by common ownership or control) for purposes
          consistent with this Privacy Policy.
        </li>
        <li>
          Business Transfers. We may share personal information when we do a
          business deal, or negotiate a business deal, involving the sale or
          transfer of all or a part of our business or assets. These deals can
          include any merger, financing, acquisition, or bankruptcy transaction
          or proceeding
        </li>
        <li>
          <p>
            Compliance with Laws and Law Enforcement; Protection and Safety. We
            may share personal information for legal, protection, and safety
            purposes.
          </p>
          <ul>
            <li>
              We may share information to comply with laws, including KYC and
              AML requiWe may share information to respond to lawful requests
              and legal processes.rements.
            </li>
            <li>
              We may share information to respond to lawful requests and legal
              processes.
            </li>
            <li>
              We may share information to protect the rights and property of the
              Company, our agents, customers, and others. This includes
              enforcing our agreements, policies, and terms of use.
            </li>
            <li>
              We may share information in an emergency. This includes protecting
              the safety of our employees and agents, our customers, or any
              person.
            </li>
          </ul>
        </li>
        <li>
          Professional Advisors and Service Providers. We may share information
          with those who need it to do work for us. These recipients may include
          third party companies and individuals to administer and provide the
          Service on our behalf (such as bill and credit card payment
          processing, customer support, hosting, email delivery and database
          management services), as well as lawyers, bankers, auditors, and
          insurers.
        </li>
        <li>
          Other. You may permit us to share your personal information with other
          companies or entities of your choosing. Those uses will be subject to
          the privacy policies of the recipient entity or entities.
        </li>
      </ul>
      <p>
        We may also share aggregated and/or anonymized data with others for
        their own uses.
      </p>
      <p className="h2">HOW INFORMATION IS SECURED</p>
      <p>
        We retain information we collect as long as it is necessary and relevant
        to fulfill the purposes outlined in this privacy policy. In addition, we
        retain personal information to comply with applicable law where
        required, prevent fraud, resolve disputes, troubleshoot problems, assist
        with any investigation, enforce our Terms of Use, and other actions
        permitted by law. To determine the appropriate retention period for
        personal information, we consider the amount, nature, and sensitivity of
        the personal information, the potential risk of harm from unauthorized
        use or disclosure of your personal information, the purposes for which
        we process your personal information and whether we can achieve those
        purposes through other means, and the applicable legal requirements. In
        some circumstances we may anonymize your personal information (so that
        it can no longer be associated with you) in which case we may use this
        information indefinitely without further notice to you. We employ
        industry standard security measures designed to protect the security of
        all information submitted through the Services. However, the security of
        information transmitted through the internet can never be guaranteed. We
        are not responsible for any interception or interruption of any
        communications through the internet or for changes to or losses of data.
        Users of the Services are responsible for maintaining the security of
        any password, biometrics, user ID or other form of authentication
        involved in obtaining access to password protected or secure areas of
        any of our digital services. In order to protect you and your data, we
        may suspend your use of any of the Services, without notice, pending an
        investigation, if any breach of security is suspected.
      </p>
      <p className="h2">INFORMATION CHOICES AND CHANGES</p>
      <p className="h3">
        Accessing, Updating, Correcting, and Deleting your Information
      </p>
      <p>
        You may access information that you have voluntarily provided through
        your account on the Services, and to review, correct, or delete it by
        sending a request to privacy@consensys.net. You can request to change
        contact choices, opt-out of our sharing with others, and update your
        personal information and preferences.
      </p>
      <p className="h3">Tracking Technologies Generally</p>
      <p>
        Regular cookies may generally be disabled or removed by tools available
        as part of most commercial browsers, and in some instances blocked in
        the future by selecting certain settings. For more information.
      </p>
      <p className="h3">Google Analytics</p>
      <p>
        You may exercise choices regarding the use of cookies from Google
        Analytics by going to https://tools.google.com/dlpage/gaoptout and
        downloading the Google Analytics Opt-out Browser Add-on.
      </p>
      <p>
        CONTACT INFORMATION. We welcome your comments or questions about this
        Policy, and you may contact us at: support@zpoken.io
      </p>
      <p>
        CHANGES TO THIS PRIVACY POLICY. We may change this privacy policy at any
        time. We encourage you to periodically review this page for the latest
        information on our privacy practices. If we make any changes, we will
        change the Last Updated date above. Any modifications to this Privacy
        Policy will be effective upon our posting of the new terms and/or upon
        implementation of the changes to the Sites (or as otherwise indicated at
        the time of posting). In all cases, your continued use of the Sites or
        Services after the posting of any modified Privacy Policy indicates your
        acceptance of the terms of the modified Privacy Policy.
      </p>
      <p className="h2">ELIGIBILITY</p>
      <p>
        If you are under the age of majority in your jurisdiction of residence,
        you may use the Services only with the consent of or under the
        supervision of your parent or legal guardian. Consistent with the
        requirements of the Children’s Online Privacy Protection Act (COPPA), if
        we learn that we have received any information directly from a child
        under age 13 without first receiving his or her parent’s verified
        consent, we will use that information only to respond directly to that
        child (or his or her parent or legal guardian) to inform the child that
        he or she cannot use the Sites and subsequently we will delete that
        information.
      </p>
      <p className="h2">NOTICE TO CALIFORNIA RESIDENTS</p>
      <p>
        Under California Civil Code Section 1789.3, California users are
        entitled to the following consumer rights notice: California residents
        may reach the Complaint Assistance Unit of the Division of Consumer
        Services of the California Department of Consumer Affairs by mail at
        1625 North Market Blvd., Sacramento, CA 95834, or by telephone at (916)
        445-1254 or (800) 952-5210. This section provides additional details
        about the personal information we collect about California consumers and
        the rights afforded to them under the California Consumer Privacy Act or
        “CCPA.” For more details about the personal information we collect from
        you, please see the “What We Collect” section above. We collect this
        information for the business and commercial purposes described in the
        “Use of Personal Information” section above. We share this information
        with the categories of third parties described in the “Sharing of
        Personal Information” section above. Company does not sell (as such term
        is defined in the CCPA) the personal information we collect (and will
        not sell it without providing a right to opt out). Please refer to
        our [Cookies Policy](https://consensys.net/privacy-policy/cookies/) for
        more information regarding the types of third-party cookies, if any,
        that we use. Subject to certain limitations, the CCPA provides
        California consumers the right to request to know more details about the
        categories or specific pieces of personal information we collect
        (including how we use and disclose this information), to delete their
        personal information, to opt out of any “sales” that may be occurring,
        and to not be discriminated against for exercising these rights.
        California consumers may make a request pursuant to their rights under
        the CCPA by contacting us at legal@consensys.net. Please note that you
        must verify your identity and request before further action is taken. As
        a part of this process, government identification may be required.
        Consistent with California law, you may designate an authorized agent to
        make a request on your behalf. In order to designate an authorized agent
        to make a request on your behalf, you must provide a valid power of
        attorney, the requester’s valid government issued identification, and
        the authorized agent’s valid government issued identification.
      </p>
      <p className="h2">NOTICE TO EU DATA SUBJECTS</p>
      <p className="h3">Personal Information</p>
      <p>
        With respect to EU data subjects, “personal information,” as used in
        this Privacy Policy, is equivalent to“personal data” as defined in
        the European Union General Data Protection Regulation (GDPR).
      </p>
      <p className="h3">Sensitive Data</p>
      <p>
        Some of the information you provide us may constitute sensitive data as
        defined in the GDPR (also referred to as special categories of personal
        data), including identification of your race or ethnicity on
        government-issued identification documents
      </p>
      <p className="h3">Legal Bases for Processing</p>
      <p>
        We only use your personal information as permitted by law. We are
        required to inform you of the legal bases of our processing of your
        personal information, which are described in the table below. If you
        have questions about the legal bases under which we process your
        personal information, contact us at support@zpoken.io.
      </p>
      <table>
        <tr>
          <th>Processing Purpose</th>
          <th>Legal Basis</th>
        </tr>
        <tr>
          <td>To provide our service</td>
          <td>
            Our processing of your personal information is necessary to perform
            the contract governing our provision of the Services or to take
            steps that you request prior to signing up for the Service.
          </td>
        </tr>
        <tr>
          <td>
            To communicate with you To optimize our platform For compliance,
            fraud prevention, and safety To provide our service
          </td>
          <td>
            To communicate with you To optimize our platform For compliance,
            fraud prevention, and safety To provide our service
          </td>
        </tr>
        <tr>
          <td>To comply with law </td>
          <td>
            We use your personal information to comply with applicable laws and
            our legal obligations, including anti-money laundering (AML) laws
            and know-your-customer (KYC) requirements.
          </td>
        </tr>
        <tr>
          <td>With your consent</td>
          <td>
            Where our use of your personal information is based upon your
            consent, you have the right to withdraw it anytime in the manner
            indicated in the Service or by contacting us at support@zpoken.io.
          </td>
        </tr>
      </table>
      <p className="h3">Use for New Purposes</p>
      <p>
        We may use your personal information for reasons not described in this
        Privacy Policy, where we are permitted by law to do so and where the
        reason is compatible with the purpose for which we collected it. If we
        need to use your personal information for an unrelated purpose, we will
        notify you and explain the applicable legal basis for that use. If we
        have relied upon your consent for a particular use of your personal
        information, we will seek your consent for any unrelated purpose.
      </p>
      <p className="h3">Your Rights</p>
      <p>
        Under the GDPR, you have certain rights regarding your personal
        information. You may ask us to take the following actions in relation to
        your personal information that we hold:
      </p>
      <ul>
        <li>
          Opt-out. Stop sending you direct marketing communications which you
          have previously consented to receive. We may continue to send you
          Service-related and other non-marketing communications.
        </li>
        <li>
          Access. Provide you with information about our processing of your
          personal information and give you access to your personal information.
        </li>
        <li>
          Correct. Update or correct inaccuracies in your personal information.
        </li>
        <li>Delete. Delete your personal information.</li>
        <li>
          Transfer. Transfer a machine-readable copy of your personal
          information to you or a third party of your choice.
        </li>
        <li>Restrict. Restrict the processing of your personal information.</li>
        <li>
          Object. Object to our reliance on our legitimate interests as the
          basis of our processing of your personal information that impacts your
          rights.
        </li>
      </ul>
      <p>
        You can submit these requests by email to privacy@consensys.net. We may
        request specific information from you to help us confirm your identity
        and process your request. Applicable law may require or permit us to
        decline your request. If we decline your request, we will tell you why,
        subject to legal restrictions. If you would like to submit a complaint
        about our use of your personal information or response to your requests
        regarding your personal information, you may contact us
        at support@zpoken.io or submit a complaint to the data protection
        regulator in your jurisdiction.
      </p>
      <p className="h3">Cross-Border Data Transfer</p>
      <p>
        Please be aware that your personal data will be transferred to,
        processed, and stored in the United States. Data protection laws in the
        U.S. may be different from those in your country of residence. You
        consent to the transfer of your information, including personal
        information, to the U.S. as set forth in this Privacy Policy by visiting
        our Sites or using our service. Whenever we transfer your personal
        information out of the EEA to the U.S. or countries not deemed by the
        European Commission to provide an adequate level of personal information
        protection, the transfer will be based on a data transfer mechanism
        recognized by the European Commission as providing adequate protection
        for personal information. Please contact us if you want further
        information on the specific mechanism used by us when transferring your
        personal information out of the EEA.
      </p>
    </div>
  );
}

export default App;
